.header-row {
  background-color: #0086A8;
}

.page-title-row {
    background-color: #a83e90;
}

.page-title-row h1 {
  color: #fff;
}
