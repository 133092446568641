.block-ui-message-container {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.block-ui-overlay {
  opacity: 0.8;
}

@keyframes ldio-inndqbdhqrk {
  0% {
    transform: rotate(0)
  }
  100% {
    transform: rotate(360deg)
  }
}

.ldio-inndqbdhqrk div {
  box-sizing: border-box !important
}

.ldio-inndqbdhqrk > div {
  position: absolute;
  width: 216px;
  height: 216px;
  top: 42px;
  left: 42px;
  border-radius: 50%;
  border: 24px solid #000;
  border-color: #0386a8 transparent #0386a8 transparent;
  animation: ldio-inndqbdhqrk 2s linear infinite;
}

.ldio-inndqbdhqrk > div:nth-child(2) {
  border-color: transparent
}

.ldio-inndqbdhqrk > div:nth-child(2) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}

.ldio-inndqbdhqrk > div:nth-child(2) div:before, .ldio-inndqbdhqrk > div:nth-child(2) div:after {
  content: "";
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  top: -24px;
  left: 72px;
  background: #0386a8;
  border-radius: 50%;
  box-shadow: 0 192px 0 0 #0386a8;
}

.ldio-inndqbdhqrk > div:nth-child(2) div:after {
  left: -24px;
  top: 72px;
  box-shadow: 192px 0 0 0 #0386a8;
}

.loadingio-spinner-dual-ring-ikz7yluk4bf {
  width: 300px;
  height: 300px;
  display: inline-block;
  overflow: hidden;
  background: none;
}

.ldio-inndqbdhqrk {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}

.ldio-inndqbdhqrk div {
  box-sizing: content-box;
}

/* generated by https://loading.io/ */
