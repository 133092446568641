body {
  font-family: 'Noto Sans', sans-serif;
  color: #000;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Open Sans', sans-serif;
}

.btn-primary {
  background-color: #a83e90;
  border-color: #a83e90;
}

.btn-secondary {
  background-color: #04586e;
  border-color: #04586e;
}
